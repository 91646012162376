<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <bread-crumb
            :breadcrumbs="[
              { label: 'Задачи', routeName: 'Task' },
              { label: 'Просмотр' },
            ]"
          ></bread-crumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h3 class="page-title">Задача</h3>
            <DetailListView
              editRoute="TaskEdit"
              :service="service"
              :columns="[
                {
                  field: 'name',
                  label: 'Название',
                  icon: 'pi pi-exclamation-circle',
                },
                {
                  field: 'start',
                  label: 'Срок',
                  content: (data) => {
                    return (
                      Str.date(service.start) + ' - ' + Str.date(service.end)
                    );
                  },
                  icon: 'pi pi-calendar',
                },
                {
                  field: 'producerName',
                  label: 'Постановщик',
                  icon: 'pi pi-user',
                  slot: 'producerName',
                },
                {
                  field: 'employeeList',
                  label: 'Сотрудники',
                  icon: 'pi pi-user',
                  slot: 'emplyeeList',
                },
                {
                  field: 'description',
                  label: 'Описание',
                  icon: 'pi pi-exclamation-circle',
                },
                {
                  field: 'taskFile',
                  label: 'Файл с заданием',
                  icon: 'pi pi-file',
                  slot: 'taskFile',
                },
                {
                  field: 'reportText',
                  label: 'Отчёт',
                  icon: 'pi pi-info',
                },
                {
                  field: 'returnFile',
                  label: 'Файл с отчётом',
                  icon: 'pi pi-file',
                  slot: 'reportFileSlot',
                },
              ]"
            >
              <template #emplyeeList="{ data }">
                <template v-for="(employee, i) in data" :key="i">
                  <router-link
                    class="text-info link_underline"
                    v-if="employee"
                    :to="{ name: 'UserShow', params: { id: employee.id } }"
                  >
                    {{ employee.surname }} {{ employee.name }}
                    {{ employee.second_name }} <br />
                  </router-link>
                </template>
              </template>
              <template #producerName="{ data }">
                <router-link
                  class="text-info link_underline"
                  v-if="data"
                  :to="{ name: 'UserShow', params: { id: data.id } }"
                >
                  {{ data.surname }} {{ data.name }} {{ data.second_name }}
                  <br />
                </router-link>
              </template>
              <template #taskFile="{ data }">
                <a
                  class="text-info link_underline"
                  v-if="data !== null"
                  :href="data"
                  >Скачать</a
                >
                <span v-else>Отсутствует</span>
              </template>
              <template #reportFileSlot="{ data }">
                <a
                  class="text-info link_underline"
                  v-if="data !== null"
                  :href="data"
                  >Скачать</a
                >
                <span v-else>Отсутствует</span>
              </template>
            </DetailListView>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/ui/BreadCrumb";
import Str from "/src/utils/Str";
import TaskService from "../services/TaskService";
import DetailListView from "@/components/ui/DetailListView.vue";

export default {
  components: {
    BreadCrumb,
    DetailListView,
  },
  data() {
    return {
      service: new TaskService({
        expand: ["employeeList", "producerName"],
      }),
      Str: Str,
    };
  },
  async created() {
    await this.service.show(this.$route.params.id);
  },
  computed: {
    returnText: function () {
      switch (this.service.return) {
        case 0:
          return "Предоставлен";
        case 1:
          return "Принят";
      }
    },
    bookkeeperText: function () {
      switch (this.service.bookkeeperReturn) {
        case 0:
          return "На рассмотрении";
        case 1:
          return "Принят";
      }
    },
  },
};
</script>
